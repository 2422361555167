import { activate as activateElement } from 'OK/capture';
import logger from 'OK/logger';
import { addBlock } from 'OK/NewsFetchCoordinator';
import { ajax } from 'OK/utils/utils';
import { parent } from 'OK/utils/dom';

/**
 * Определяем, видно ли сейчас элемент частично внизу экрана. Не проверяет перекрыт элемент или нет
 * @param {HTMLElement} el
 * @param {number} offset
 * @return {boolean}
 */
function isElementPartiallyInViewportBottom(el, offset) {
    var rect = el.getBoundingClientRect();
    return rect.top > (window.innerHeight - offset) && rect.left > 0 &&
        rect.top < window.innerHeight &&
        rect.right < window.innerWidth;
}

/**
 * Определяем, видно ли сейчас элемент частично. Не проверяет перекрыт ли элемент или нет
 * @param {HTMLElement} el
 * @param {number} visiblePartThreshold from 0 exclusive to 1 inclusive
 * @return {boolean}
 */
function isElementPartiallyInViewport(el, visiblePartThreshold) {
    var rect = el.getBoundingClientRect(),
        visibleHeight = Math.min(rect.bottom, window.innerHeight)
            - Math.max(0, rect.top),
        visibleWidth = Math.min(rect.right, window.innerWidth)
            - Math.max(0, rect.left),
        elementHeight = el.offsetHeight,
        elementWidth = el.offsetWidth;

    return visibleHeight > 0 && visibleWidth > 0 &&
        elementHeight * visiblePartThreshold <= visibleHeight &&
        elementWidth * visiblePartThreshold <= visibleWidth;
}

/**
 * @param {HTMLElement} element
 */
function activate(element) {
    /** @type {HTMLElement} */
    this.parent = parent(element, 'feed-list');
    /** @type {number} */
    this.offset = parseInt(element.getAttribute('data-offset'), 10) || 200;
    /** @type {boolean} */
    this.unsubscribe = element.getAttribute('data-unsubscribe') === 'true';
    /** @type {number} */
    this.checkMore = parseInt(element.getAttribute('data-check-more'), 10) || 5;
    /** @type {number} */
    this.minCreated = parseInt(element.getAttribute('data-min-created'), 10) || 0;

    addBlock('MainFeedsNewFeedPush');
}

function deactivate() {
    if (this.unsubscribe) {
        ajax({
            type: 'POST',
            url: '/web-api/feed/markAsInactive'
        }).fail(function() {
            logger.error('Failed to mark as inactive in feed');
        });
    }
}

function setNewContent(data) {
    /** @type {HTMLElement} */
    var element = document.createElement('div');
    element.innerHTML = data;
    var newFeeds = element.childNodes;
    if (newFeeds.length === 0) {
        return;
    }

    /** @type {Object} */
    var newFeedsById = getNewFeedsById(newFeeds, this.minCreated);
    if (!newFeedsById) {
        return;
    }

    /** @type {HTMLElement} */
    var feedToReplace;
    /** @type {HTMLElement} */
    var lastVisibleFeed;

    var children = this.parent.childNodes;
    if (children.length === 0) {
        return;
    }

    var checkPlace = true;
    var maxChildIndex = children.length;
    for (var i = 0; i < maxChildIndex; i++) {
        var feed = children[i];
        if (feed.className.indexOf('feed') >= 0) {
            var feedId = getFeedId(feed);
            if (feedId) {
                delete newFeedsById[feedId];
            }

            if (!checkPlace) {
                continue;
            }

            if (isElementPartiallyInViewportBottom(feed, this.offset)) {
                feedToReplace = feed;
            } else if (feedToReplace) {
                // уже зашли слишком далеко
                checkPlace = false;
            } else if (isElementPartiallyInViewport(feed, 0.2)) {
                lastVisibleFeed = feed;
            } else if (lastVisibleFeed) {
                // дальше не будет фидoв на экране
                checkPlace = false;
            }

            if (!checkPlace) {
                if (this.checkMore == 0) {
                    break;
                }

                // места не найдем, но поищем еще не добавлен ли фид
                if (i + this.checkMore < maxChildIndex) {
                    maxChildIndex = i + this.checkMore;
                }
            }
        }
    }

    for (var newFeedId in newFeedsById) {
        if (!newFeedsById.hasOwnProperty(newFeedId)) {
            continue;
        }

        /** @type {HTMLElement} */
        var newFeed = newFeedsById[newFeedId];
        if (!newFeed) {
            continue;
        }

        if (feedToReplace) {
            // вставим перед тем который видно снизу чутчут
            this.parent.insertBefore(newFeed, feedToReplace);
        } else if (lastVisibleFeed) {
            // не получилось - после последнего на экране
            this.parent.insertBefore(newFeed, lastVisibleFeed.nextSibling);
        } else {
            continue;
        }

        activateElement(newFeed);
    }

}

/**
 * @param newFeeds
 * @param {number} minCreated
 * @return {Object}
 */
function getNewFeedsById(newFeeds, minCreated) {
    var newFeedsById = {};
    var feedsAdded = false;

    for (var j = newFeeds.length - 1; j >= 0; j--) {
        /** @type {HTMLElement} */
        var newFeed = newFeeds[j];
        if (minCreated > 0) {
            var created = getCreated(newFeed);
            if (created && created < minCreated) {
                continue;
            }
        }

        var newFeedId = getFeedId(newFeed);
        if (newFeedId) {
            newFeedsById[newFeedId] = newFeed;
            feedsAdded = true;
        }
    }

    if (!feedsAdded) {
        return null;
    }

    return newFeedsById;
}

function getFeedId(element) {
    var seenParamsAttr = element.getAttribute('data-seen-params');
    if (!seenParamsAttr && element.firstElementChild) {
        seenParamsAttr = element.firstElementChild.getAttribute('data-seen-params');
    }
    if (seenParamsAttr) {
        var seenParams = JSON.parse(seenParamsAttr);
        if (seenParams.data) {
            return seenParams.data.gId;
        }
    }

    return null;
}

function getCreated(element) {
    var seenParamsAttr = element.getAttribute('data-seen-params');
    if (!seenParamsAttr && element.firstElementChild) {
        seenParamsAttr = element.firstElementChild.getAttribute('data-seen-params');
    }

    if (seenParamsAttr) {
        var seenParams = JSON.parse(seenParamsAttr);
        if (seenParams.data) {
            return seenParams.data.created;
        }
    }

    return null;
}

export default { activate, deactivate, setNewContent };

export { activate, deactivate, setNewContent };
